import {
  FC, memo, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import type { Actions as TypeActions } from 'types';
import type { IActionRequest } from 'store/interfaces/IData';
import { useStore } from 'store/store';
import RotateIconButton from 'components/shared/rotateIconButton/RotateIconButton';
import IconButton from 'components/shared/iconButton/IconButton';
import StepName from 'components/shared/stepName/StepName';
import {
  Actions as ActionsEnum, Statuses, PropertySwitch, RotateValue, BaseActions, RotateActions,
} from 'enums';
import { DEFAULT_IV_WIDTH, DEFAULT_IV_HEIGHT } from '../../../../../constants';

import './Actions.scss';

const initialSettings: IActionRequest = {
  enableRotate: PropertySwitch.OFF,
  enableMirror: PropertySwitch.OFF,
  enableScaling: PropertySwitch.OFF,
  enableRotateValue: undefined,
  ivHeight: DEFAULT_IV_HEIGHT,
  ivWidth: DEFAULT_IV_WIDTH,
};

const initialImageData = {
  url: '',
  height: 0,
  width: 0,
};

const Actions: FC = () => {
  const { t } = useTranslation();
  const {
    taskStatus, setTaskStatus, orderData, changeFile, resetFile, setImageData,
  } = useStore(useShallow((state) => (
    {
      taskStatus: state.taskStatus,
      setTaskStatus: state.setTaskStatus,
      orderData: state.orderData,
      changeFile: state.changeFile,
      resetFile: state.resetFile,
      setImageData: state.setImageData,
    }
  )));

  const handleClick = useCallback(async (action: string) => {
    const getRotateSettings = (value: RotateValue) => {
      return {
        ...initialSettings,
        enableRotate: PropertySwitch.ON,
        enableRotateValue: value,
      };
    };
    setImageData(initialImageData);
    setTaskStatus(Statuses.uploading);
    try {
      switch (action) {
        case ActionsEnum.rotate_90: {
          await changeFile(getRotateSettings(RotateValue.ANGLE_90));
          break;
        }
        case ActionsEnum.rotate_180: {
          await changeFile(getRotateSettings(RotateValue.ANGLE_180));
          break;
        }
        case ActionsEnum.rotate_270: {
          await changeFile(getRotateSettings(RotateValue.ANGLE_270));
          break;
        }
        case ActionsEnum.mirror: {
          await changeFile({
            ...initialSettings,
            enableMirror: PropertySwitch.ON,
          });
          break;
        }
        case ActionsEnum.stretch: {
          await changeFile({
            ...initialSettings,
            enableScaling: PropertySwitch.ON,
            ivHeight: orderData.ivHeight,
            ivWidth: orderData.ivWidth,
          });
          break;
        }
        case ActionsEnum.reset: {
          await resetFile();
          break;
        }
        default: break;
      }
    } catch (error) {
      console.error(error);
    }
    setTaskStatus(Statuses.processing);
  }, [setTaskStatus]);

  const isDisabled = useMemo(() => [
    Statuses.uploading,
    Statuses.processing,
    Statuses.pending,
  ].includes(taskStatus.status), [taskStatus]);

  const actions = useMemo(() => Object.keys(RotateActions).map((action) => t(action)), []);

  const rotateActionButton = useMemo(() => (
    <RotateIconButton
      handler={handleClick}
      text={t('rotate')}
      actions={actions}
      disabled={isDisabled}
    />
  ), [isDisabled]);

  const baseActionButtons = useMemo(() => {
    return Object.keys(BaseActions).map((action) => (
      <IconButton
        key={action}
        action={action as TypeActions}
        text={t(action)}
        disabled={isDisabled}
        onClick={() => handleClick(action)}
      />
    ));
  }, [isDisabled]);

  return (
    <Card className="actionsList">
      <CardContent className="actionsList-content">
        <StepName step={2} />
        <div className="actionsList-content__list">
          {rotateActionButton}
          {baseActionButtons}
        </div>
      </CardContent>
    </Card>
  );
};

export default memo(Actions);
