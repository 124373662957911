export enum AppRoutes {
    base = '/',
    upload = '/upload',
    reupload = '/reupload',
    feedback = '/feedback',
    proof = '/proof',
    notFound = '/notFound',
}

export enum AppLangs {
    en = 'en',
    nl = 'nl',
    de = 'de',
}

export enum QueryParams {
    JOB_ID = 'jobId',
    LANG = 'lang',
    ORDER_PRODUCT = 'order_product',
    ORDER_LINE = 'order_line',
    IV_WIDTH = 'ivWidth',
    IV_HEIGHT = 'ivHeight',
}

export enum OrderStatuses {
    created = 'created',
    uploaded = 'uploaded',
    failed = 'failed',
    passed = 'passed',
    approved = 'approved',
    rejected = 'rejected'
}

export enum Statuses {
    starting = 'starting',
    uploading ='uploading',
    processing = 'processing',
    pending = 'pending',
    cancelled = 'cancelled',
    failed = 'failed',
    completed = 'completed',
}

export enum BaseActions {
    mirror = 'mirror',
    stretch = 'stretch',
    reset = 'reset',
}

export enum RotateActions {
    rotate_90 = 'rotate_90',
    rotate_180 = 'rotate_180',
    rotate_270 = 'rotate_270',
}

export enum RotateValue {
    ANGLE_90 = 90,
    ANGLE_180 = 180,
    ANGLE_270 = 270,
}

export enum PropertySwitch {
    OFF,
    ON,
}

export enum FolderNames {
    reports = 'reports',
    storage = 'storage',
}

export const Actions = { ...BaseActions, ...RotateActions };
