import {
  FC, memo, ComponentRef, useCallback, useEffect, useRef, useState,
} from 'react';
import PrismaZoom from 'react-prismazoom';

import SvgIcon from '@mui/material/SvgIcon';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Progress from 'components/shared/progress/Progress';

import { ReactComponent as ZoomIn } from 'assets/icons/Zoom_In.svg';
import { ReactComponent as ZoomOut } from 'assets/icons/Zoom_Out.svg';
import { useStore } from 'store/store';

import './ImageArea.scss';

interface IImageAreaProps {
  isLoading?: boolean;
}

const ALT_TEXT = 'Uploaded artwork';
const MAX_IMG_PART = 0.75;
const ZOOM_STEP = 0.5;

const ImageArea: FC<IImageAreaProps> = ({ isLoading }) => {
  const screenRef = useRef<HTMLDivElement>(null);
  const prismaRef = useRef<ComponentRef<typeof PrismaZoom>>(null);

  const { url, width, height } = useStore((state) => state.imageData);
  const [imgSize, setImgSize] = useState({
    currentWidth: 0,
    currentheight: 0,
  });

  useEffect(() => {
    if (screenRef.current) {
      const maxWidth = screenRef.current.offsetWidth * MAX_IMG_PART;
      const maxHeight = screenRef.current.offsetHeight * MAX_IMG_PART;
      let scale: number;

      if (width > maxWidth && height > maxHeight) {
        scale = Math.min(maxWidth, maxHeight) / Math.max(width, height);
      } else if (width > maxWidth) {
        scale = maxWidth / width;
      } else if (height > maxHeight) {
        scale = maxHeight / height;
      } else scale = 1;

      setImgSize({
        currentWidth: width * scale,
        currentheight: height * scale,
      });
    }
  }, [screenRef, width, height]);

  const onClickOnZoomOut = useCallback(() => {
    prismaRef.current?.zoomOut(ZOOM_STEP);
  }, [prismaRef]);

  const onClickOnZoomIn = useCallback(() => {
    prismaRef.current?.zoomIn(ZOOM_STEP);
  }, [prismaRef]);

  return (
    <div className="imageArea">
      <div className="imageArea__screen" ref={screenRef}>
        {isLoading ? <Progress /> : (
          <PrismaZoom ref={prismaRef} scrollVelocity={1.5}>
            {!!url.length && (
              <img
                alt={ALT_TEXT}
                src={url}
                width={imgSize.currentWidth}
                height={imgSize.currentheight}
              />
            )}
          </PrismaZoom>
        )}
      </div>
      <ButtonGroup className="imageArea__buttons">
        <Button
          className="imageArea__buttons__minus"
          onClick={onClickOnZoomOut}
        >
          <SvgIcon>
            <ZoomOut />
          </SvgIcon>
        </Button>
        <Button
          className="imageArea__buttons__plus"
          onClick={onClickOnZoomIn}
        >
          <SvgIcon>
            <ZoomIn />
          </SvgIcon>
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default memo(ImageArea);
