import { FC, useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { IJobInfo, IReportResult, ServerResponse } from 'store/interfaces/IData';
import {
  AppRoutes, OrderStatuses, QueryParams, Statuses, FolderNames,
} from 'enums';
import { useStore } from 'store/store';

import Info from 'components/shared/info/Info';
import ScreenMessage from 'components/shared/screenMessage/ScreenMessage';
import Download from './download/Download';
import ImageArea from '../feedback/imageArea/ImageArea';
import { DEFAULT_IV_WIDTH, DEFAULT_IV_HEIGHT, DEFAULT_FILENAME } from '../../../../constants';

const REDIRECT_STATUSES = [
  OrderStatuses.created,
  OrderStatuses.uploaded,
  OrderStatuses.approved,
  OrderStatuses.rejected,
];

const Proof: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    jobId, orderData, setJobId, setOrderData, setFileData,
    getJobInfo, getResultProof, getReportFileByName, setPreflightedFile, setImageData,
  } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [result, setResult] = useState<ServerResponse>({
    preflightedFile: '',
    previewImage: '',
    report: '',
    reportResult: {} as IReportResult,
    status: Statuses.starting,
    error: '',
  });

  useEffect(() => {
    setJobId(searchParams.get(QueryParams.JOB_ID) ?? '');
    setOrderData({
      product: searchParams.get(QueryParams.ORDER_PRODUCT) ?? '',
      line: searchParams.get(QueryParams.ORDER_LINE) ?? '',
      ivWidth: Number(searchParams.get(QueryParams.IV_WIDTH)) ?? DEFAULT_IV_WIDTH,
      ivHeight: Number(searchParams.get(QueryParams.IV_HEIGHT)) ?? DEFAULT_IV_HEIGHT,
    });
  }, []);

  useEffect(() => {
    const getData = async () => {
      await getResultProof()
        .then((result: ServerResponse) => {
          setResult({
            ...result,
            status: result.status,
            error: result.error,
          });
        })
        .catch((error) => {
          console.log(error);
          setJobId('');
          navigate(AppRoutes.notFound);
        });
    };

    if (jobId.length) {
      setIsLoading(true);
      getJobInfo(jobId)
        .then((info: IJobInfo) => {
          if (REDIRECT_STATUSES.includes(info.status) || !info.generateProofPage) {
            navigate(AppRoutes.notFound);
          }
          getData();
        })
        .catch(() => navigate(AppRoutes.notFound));
    }
  }, [jobId, orderData]);

  useEffect(() => {
    const fetchData = async () => {
      await getReportFileByName(FolderNames.storage, result.previewImage ?? '').then((blob) => {
        const img = new Image();
        const imageUrl = URL.createObjectURL(blob as Blob);
        img.src = imageUrl;
        img.onload = () => {
          setImageData({
            url: imageUrl,
            height: img.naturalHeight,
            width: img.naturalWidth,
          });
        };
      });
      if (result.preflightedFile !== undefined) {
        await getReportFileByName(FolderNames.storage, result.preflightedFile).then((blob) => {
          const fileUrl = URL.createObjectURL(blob as Blob);
          const fileName = result.preflightedFile?.split('/').at(-1)?.split('.')[0] || DEFAULT_FILENAME;
          setFileData({
            name: fileName,
            size: '',
          });
          setPreflightedFile(fileUrl);
        });
      }
      setIsLoading(false);
    };
    fetchData().catch(console.error);
  }, [result]);

  return !isAllowed ? (
    <section className="main-container">
      <ImageArea isLoading={isLoading} />
      <div className="aside proof">
        <Info />
        <Download isAllowed={isAllowed} setIsAllowed={setIsAllowed} />
      </div>
    </section>
  ) : (
    <ScreenMessage message={t('label_accept_message')} />
  );
};

export default Proof;
