import {
  Dispatch, FC, SetStateAction, useCallback, MouseEvent,
} from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as Ok } from 'assets/icons/Ok.svg';
import { ReactComponent as Clock } from 'assets/icons/Clock.svg';
import { ReactComponent as Search } from 'assets/icons/Search.svg';
import { ReactComponent as Mail } from 'assets/icons/Mail.svg';
import { ReactComponent as Warning } from 'assets/icons/Warning.svg';

interface IToggleButtonProps {
    proof: boolean;
    value: boolean;
    setProof: Dispatch<SetStateAction<boolean>>;
}

const ICONS = [
  {
    icon: <Clock />,
    text: 'clock_desc',
    yes: true,
  },
  {
    icon: <Search />,
    text: 'search_desc',
    yes: true,
  },
  {
    icon: <Mail />,
    text: 'mail_desc',
    yes: true,
  },
  {
    icon: <Warning />,
    text: 'warning_desc',
    yes: false,
  },
];

const ToggleButton: FC<IToggleButtonProps> = ({ proof, value, setProof }) => {
  const { t } = useTranslation();
  const handleToogle = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setProof(!!+event.currentTarget.value);
  }, []);

  return (
    <Button
      className={`toggle-content-area__btn ${(value ? proof : !proof) ? 'toggle-content-area__btn-active' : ''}`}
      value={+value}
      onClick={handleToogle}
    >
      <div className="toggle-content-area__btn-top">
        <Typography>
          <SvgIcon>
            <Ok />
          </SvgIcon>
          {t(`${value ? 'yes' : 'no'}`)}
        </Typography>
      </div>
      <div className="toggle-content-area__btn-bottom">
        {
            ICONS.map(({ icon, yes, text }, index) => {
              return (value ? yes : !yes) ? (
                <Typography key={index}>
                  <SvgIcon>
                    {icon}
                  </SvgIcon>
                  {t(text)}
                </Typography>
              ) : null;
            })
        }
      </div>
    </Button>
  );
};

export default ToggleButton;
