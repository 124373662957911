import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Info from 'components/shared/info/Info';
import DeleteForm from 'components/shared/deleteForm/DeleteForm';
import ScreenMessage from 'components/shared/screenMessage/ScreenMessage';

import type { IReportResult, ServerResponse } from 'store/interfaces/IData';
import { useStore } from 'store/store';
import {
  AppRoutes, QueryParams, Statuses, FolderNames,
} from 'enums';
import {
  STYLES_BUTTON, REREQUEST_TIME, DEFAULT_IV_WIDTH,
  DEFAULT_IV_HEIGHT, STORAGE_LANG, HIDE_SNACKBAR_TIME,
} from '../../../../constants';

import Actions from './actions/Actions';
import ImageArea from './imageArea/ImageArea';
import ErrorsList from './errorsList/ErrorsList';
import ProofToggle from './proofToggle/ProofToggle';
import SnackBar from '../../../shared/snackBar/SnackBar';

import './Feedback.scss';

const Feedback: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    isLoaded, jobId, orderData, checks, taskStatus,
    getResult, getReportFileByName, getTaskStatus,
    setLoaded, setImageData, setPreflightedFile, setTaskStatus,
    destroySession, reset, acceptJob,
  } = useStore();
  const [approve, setApprove] = useState(false);
  const [accept, setAccept] = useState(false);
  const [proof, setProof] = useState(false);
  const [error, setError] = useState(null);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [result, setResult] = useState<ServerResponse>({
    preflightedFile: '',
    previewImage: '',
    report: '',
    reportResult: {} as IReportResult,
    status: Statuses.starting,
    error: '',
  });

  const handleDelete = useCallback(async () => {
    await destroySession();
    navigate(`${AppRoutes.upload}?${QueryParams.JOB_ID}=${jobId}&${QueryParams.LANG}=${localStorage.getItem(STORAGE_LANG)}&${QueryParams.ORDER_PRODUCT}=${orderData.product}&${QueryParams.ORDER_LINE}=${orderData.line}&${QueryParams.IV_WIDTH}=${DEFAULT_IV_WIDTH}&${QueryParams.IV_HEIGHT}=${DEFAULT_IV_HEIGHT}`);
    reset();
  }, []);

  const handleAccept = useCallback(() => {
    acceptJob({ generateProofPage: String(proof) }).catch((e) => {
      setError(e.message);
    });
    setAccept(true);
  }, [proof]);

  useEffect(() => {
    if (!isLoaded) {
      navigate(AppRoutes.base);
      reset();
    }
    setLoaded(false);
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (taskStatus.status === Statuses.processing || taskStatus.status === Statuses.pending) {
      intervalId = setInterval(() => {
        getTaskStatus().catch((error) => {
          setTaskStatus(Statuses.starting);
          console.error(error);
        });
      }, REREQUEST_TIME);
    } else if (taskStatus.status === Statuses.completed || taskStatus.status === Statuses.failed) {
      clearInterval(intervalId);
      getResult()
        .then((result: ServerResponse) => {
          setResult({
            ...result,
            status: result.status,
            error: result.error,
          });
        })
        .catch((error) => {
          setTaskStatus(Statuses.starting);
          console.error(error);
        });
    }
    return () => clearInterval(intervalId);
  }, [taskStatus]);

  const isLoading = useMemo(() => [
    Statuses.uploading,
    Statuses.processing,
    Statuses.pending,
  ].includes(taskStatus.status), [taskStatus]);

  useEffect(() => {
    const fetchData = async () => {
      if (taskStatus.status === Statuses.completed) {
        await getReportFileByName(FolderNames.reports, result.previewImage ?? '').then((blob) => {
          const img = new Image();
          const imageUrl = URL.createObjectURL(blob as Blob);
          img.src = imageUrl;
          img.onload = () => {
            setImageData({
              url: imageUrl,
              height: img.naturalHeight,
              width: img.naturalWidth,
            });
          };
        });
        if (result.preflightedFile !== undefined) {
          await getReportFileByName(FolderNames.reports, result.preflightedFile).then((blob) => {
            const fileUrl = URL.createObjectURL(blob as Blob);
            setPreflightedFile(fileUrl);
          });
        }
        setLoaded(true);
      } else if (result.status === Statuses.failed) {
        console.error('Error downloading preflightedFile');
      }
    };
    fetchData().catch(console.error);
  }, [result]);

  useEffect(() => {
    if (error) {
      setIsSnackBarOpen(true);
      setTimeout(() => {
        setError(null);
        setIsSnackBarOpen(false);
      }, HIDE_SNACKBAR_TIME);
    }
  }, [error]);

  const handleSnackbarClose = () => {
    setIsSnackBarOpen(false);
  };

  return (
    <>
      {
      !accept ? (
        <section className="main-container">
          <ImageArea isLoading={isLoading} />
          <div className="aside feedback">
            <div className="feedback-wrapper">
              <Info>
                <DeleteForm handleClick={handleDelete} />
              </Info>
              <ErrorsList
                errors={checks}
                approve={approve}
                setApprove={setApprove}
              />
              <Actions />
              <ProofToggle
                proof={proof}
                setProof={setProof}
              />
              <Button
                className="feedback__btn"
                variant="contained"
                sx={STYLES_BUTTON}
                disabled={!approve || isLoading}
                onClick={handleAccept}
              >
                {t('btn_complete')}
              </Button>
            </div>
          </div>
        </section>
      ) : (
        <ScreenMessage message={t('label_accept_message')} />
      )
    }
      <SnackBar
        isOpen={isSnackBarOpen}
        successes={[]}
        errors={error ? [error] : []}
        handleClose={handleSnackbarClose}
      />
    </>
  );
};

export default Feedback;
